import { Selection, SelectionMode, Stack, TextField } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ShareModal } from "src/chat";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { DropZone } from "src/spintr/components";
import FilePicker from "src/spintr/components/FilePicker";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, RightsPopup } from "src/ui/components";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { validZohoFormat } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ActionMenu, SpintrUser } from "../../ui";
import {
    abortNewFolder, deleteFile, deleteFolder,
    deleteGoogleFolder,
    deleteOfficeFolder, queryFilesByFolder,
    saveNewFolder, setRenamingItem, setSelectedItems, updateFile, updateFolder, uploadedFile
} from "../file-actions";
import { findFolderById, setCopiedFolder, setCurrentFolderId } from "../folder-actions";
import { checkFileExtension } from "../utils";
import { setZohoPopup } from "../zoho-actions";
import CreateFilePopup from "./CreateFilePopup";
import "./FileBrowser.scss";
import FileDownloadPopup from "./FileDownloadPopup";

interface IProps {
    id: string;
    source: Spintr.FolderRootSource;
    fileId: string;
    folder: any;
    searchText: string;
    items: Spintr.IFileBrowserFile[];
    totalCount: number;
    errors: any;
    isLoadingNewFile: boolean;
    isLoadingItems: boolean;
    isFileUser: boolean;
    isEditor: boolean;
    isAdmin: boolean;
    selectedItems: Spintr.IFileBrowserFile[];
    currentUserId: number;
    hideShare: boolean;
    enableFolderDownload: boolean;
    enableZoho: boolean;
    isSmallViewMode: boolean;
    dispatch: (action: Action) => Promise<any>;
    location: Location;
    history: any;
    copiedFolder: Spintr.IFileBrowserFile;
    disableFileLocking: boolean;
    restrictedExtensions: string;
    rootFolderId?: string;

    group?: any;
    isInTeamsApp: boolean;
}

interface IState {
    isLoading: boolean;
    rightsPopup: any;
    createFilePopup: any;
    fileDownloadPopup: any;
    infoDialogMessage?: string;
    isInfoDialogOpen?: boolean;
    isFilePickerOpen?: boolean;
    selectedItem?: Spintr.IFileBrowserFile;
    shareModal: any;

    nextPageToken?: string;
    hasMore?: boolean;
}

class FileBrowser extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private draggedItem: Spintr.IFileBrowserFile;
    private selection: Selection;

    constructor(props) {
        super(props);

        this.state = {
            createFilePopup: {},
            fileDownloadPopup: {},
            isLoading: false,
            rightsPopup: {},
            shareModal: {
                isOpen: false,
            },
        };

        this.fetchData = this.fetchData.bind(this);

        this.selection = new Selection({
            onSelectionChanged: () => {
                this.props.dispatch(setSelectedItems(this.selection.getSelection() as Spintr.IFileBrowserFile[]));
            },
        });
    }

    public componentDidMount() {
        this.props.dispatch(setCurrentFolderId(this.props.id));
        this.findFolder();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.id !== prevProps.id && this.props.id !== "0") {
            this.findFolder();
        }

        if (
            this.props.searchText !== prevProps.searchText ||
            (prevProps.isLoadingNewFile && !this.props.isLoadingNewFile) ||
            (this.props.id !== prevProps.id && this.props.id !== "0")
        ) {
            this.props.dispatch(setCurrentFolderId(this.props.id));

            this.setState(
                (state) => ({ ...state, nextPageToken: undefined }),
                () => this.listRef && this.listRef.current && this.listRef.current.reFetch(),
            );
        }
    }

    public render() {
        return <div className="FileBrowser">{this.renderComponent()}</div>;
    }

    protected findFolder() {
        // @ts-ignore
        if (this.props.folder) {
            return;
        }

        // @ts-ignore
        this.props.dispatch(
            // @ts-ignore
            findFolderById(
                this.props.id,
                this.props.source,
                this.props.group && this.props.group.externalId
            )
        );
    }

    protected saveNewFolder = (e) => {
        e.preventDefault();

        var name = e.target.value;
        if (name.length == 0) {
            this.abortNewFolder();
            return;
        }

        // TODO: Kolla noggrannare på detta, buggar lite om man klickar på ny mapp igen

        var isOffice365 =
            this.props.source == SpintrTypes.FolderRootSource.Office365Public ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Private ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Group;

        const params: any = {
            name: name,
            source: this.props.source,
            parentId: this.props.id,
        };

        if (isOffice365) {
            params.parentId = "0";
            params.parentIdstr = this.props.id;
            params.externalId = this.props.group && this.props.group.externalId;
        } else if (this.props.source === SpintrTypes.FolderRootSource.GoogleDrive) {
            params.parentId = "0";
            params.parentIdStr = this.props.id;
        }

        this.props.dispatch(saveNewFolder(params));
    };

    protected abortNewFolder = () => {
        this.props.dispatch(abortNewFolder());
    };

    protected delete = (item: Spintr.IFileBrowserFile) => {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                message: localize("VillDuVerkligenTaBortX").replace("{{X}}", item.name),
                onConfirm: () => {
                    if (item.isDirectory) {
                        if (this.props.source === SpintrTypes.FolderRootSource.Office365Private ||
                            this.props.source === SpintrTypes.FolderRootSource.Office365Public) {
                            this.props.dispatch(deleteOfficeFolder(item.id, this.props.source));
                        } else if (this.props.source === SpintrTypes.FolderRootSource.GoogleDrive) {
                            this.props.dispatch(deleteGoogleFolder(item.id, this.props.source));
                        } else {
                            this.props.dispatch(deleteFolder(item.id));
                        }
                    } else {
                        this.props.dispatch(deleteFile(item.id, this.props.source));
                    }
                },
            })
        );
    };

    protected closeRightsPopup = () => {
        this.setState({
            rightsPopup: {
                isOpen: false,
            },
        });
    };

    protected dismissInfoDialog = () => {
        this.setState({
            isInfoDialogOpen: false,
        });
    };

    protected onShareDismiss = () => {
        this.setState({
            shareModal: { isOpen: false },
        });
    };

    protected uploadFile = (file: File, folderId: string) => {
        if (checkFileExtension(file, this.props.restrictedExtensions)) {
            this.setState({
                isInfoDialogOpen: true,
                infoDialogMessage: `${localize("FilTypInteTillaten")}: ${file.name}`,
            });
            return;
        }

        let body = new FormData();
        body.append("type", "3");
        body.append("folderId", folderId);
        body.append("file", file);

        // TODO: Group file upload? feedId

        var url = "/api/uploads";

        if (
            this.props.source == SpintrTypes.FolderRootSource.Office365Public ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Private ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Group
        ) {
            url = "/api/uploads/office365";
            body.append("source", this.props.source.toString());
        } else if (this.props.source === SpintrTypes.FolderRootSource.GoogleDrive) {
            url = "/api/v1/files/google";
        }

        return api.post(url, body).then((response: AxiosResponse) => {
            if(this.props.id === folderId)
            {
                this.props.dispatch(uploadedFile(response.data.Result));
            }
        });
    };

    protected runUploadQueue = (files: File[], folderId: string) => {
        var slice = files.length > 5 ? 5 : files.length;
        var promises = files.slice(0, slice);
        var p = [];

        for (let file of promises) {
            p.push(this.uploadFile(file, folderId));
        }

        Promise.all(p).then(() => {
            files = files.slice(slice, files.length);

            if (files.length > 0) {
                this.runUploadQueue(files, folderId);
            }
        });
    };

    protected onFilesSelected = (filesInput: FileList) => {
        let files = Array.from(filesInput);
        const folderId = this.props.id.toString();

        this.runUploadQueue(files, folderId);
    };

    protected fetchData(skip, take, columnId, isAscending, searchQuery, cancelToken, fetchMore) {
        return new Promise((resolve, reject) => {
            const promise = this.props.dispatch(queryFilesByFolder(this.props.id, {
                alternativeSorting: true,
                externalId: this.props.group && this.props.group.externalId,
                isAscending,
                orderByColumn: columnId,
                searchText: this.props.searchText,
                skip,
                skipToken: this.state.nextPageToken,
                source: this.props.source,
                take,
            }));

            promise.then(result => {
                if (result?.action?.type !== "QUERY_FILES_BY_FOLDER_FULFILLED") {
                    return;
                }

                const response = result?.value as AxiosResponse<any>;
                if (response?.status !== 200) {
                    return;
                }

                const { hasMore, nextPageToken } = response.data || {};

                this.setState((state) => ({ ...state, hasMore, nextPageToken }));

                resolve(undefined);
            });

            promise.catch(() => {
                reject();
            })
        });
    }

    protected renderComponent() {
        // @ts-ignore
        if (!this.props.folder || this.props.folder.get("isLoading")) {
            //return <Loader />;
        }

        var isGroup = this.props.location.pathname.includes("group");
        var source = Number(this.props.source);
        
        var isValidSource =
            source == SpintrTypes.FolderRootSource.SpintrGroup ||
            source == SpintrTypes.FolderRootSource.Office365Private ||
            source == SpintrTypes.FolderRootSource.Office365Group;

        var isOffice365 =
            source == SpintrTypes.FolderRootSource.Office365Public ||
            source == SpintrTypes.FolderRootSource.Office365Private ||
            source == SpintrTypes.FolderRootSource.Office365Group;

        const isGoogle = source == SpintrTypes.FolderRootSource.GoogleDrive;

        var canWrite = this.props.isFileUser || this.props.isEditor || this.props.isAdmin || isGroup || isValidSource;

        return (
            <div className="wrap">
                <div className="body">
                    <SpintrList
                        ref={this.listRef}
                        fetch={this.fetchData}
                        isLoading={this.props.isLoadingItems || this.state.isLoading}
                        data={{
                            data: this.props.items,
                            totalCount: !isOffice365
                                ? this.props.totalCount
                                : (this.props.items.length + (this.state.hasMore ? 1 : 0))
                        }}
                        columns={[
                            {
                                name: localize("Namn"),
                                fieldName: "name",
                                minWidth: 250,
                                onRender: (item: Spintr.IFileBrowserFile) => {
                                    var url = `${this.props.group
                                            ? `/groups/${this.props.group.id}/files`
                                            : `/files/${this.props.source}`
                                        }/${item.isDirectory
                                            ? `d${item.id}`
                                            : isOffice365
                                                ? `365f${item.id}`
                                                : `f${item.id}`
                                        }`;
                                    return (
                                        <span>
                                            {item.previewImage ? (
                                                <div
                                                    className="preview-image"
                                                    style={{ backgroundImage: `url(${item.previewImage})` }}
                                                />
                                            ) : (
                                                <div className="icon-wrapper">
                                                    <Visage2Icon icon={item.isDirectory ? "folder" : "document-text"} title={localize("Last")} />
                                                </div>
                                            )}
                                            {
                                                // @ts-ignore
                                                item.renaming ? (
                                                    <form onSubmit={e => e.preventDefault()} style={{ display: "inline-block" }}>
                                                        <TextField
                                                            placeholder={item.name}
                                                            defaultValue={item.name}
                                                            onBlur={(e) => { }}
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    var name = (e.target as HTMLInputElement).value;
                                                                    if (item.isDirectory && !isOffice365 && !isGoogle) {
                                                                        this.props.dispatch(
                                                                            updateFolder(
                                                                                {
                                                                                    id: item.id,
                                                                                    name: (e.target as HTMLInputElement)
                                                                                        .value,
                                                                                    parentId: this.props.id,
                                                                                    source: this.props.source,
                                                                                    externalId:
                                                                                        this.props.group &&
                                                                                        this.props.group.externalId,
                                                                                },
                                                                                this.props.id
                                                                            )
                                                                        );
                                                                    } else {
                                                                        this.props.dispatch(
                                                                            updateFile(
                                                                                {
                                                                                    id: item.id,
                                                                                    name: name,
                                                                                    folderId: this.props.id,
                                                                                    source: this.props.source,
                                                                                    externalId:
                                                                                        this.props.group &&
                                                                                        this.props.group.externalId,
                                                                                },
                                                                                this.props.id
                                                                            )
                                                                        );
                                                                    }
                                                                }

                                                                if (e.keyCode === 27) {
                                                                    this.props.dispatch(setRenamingItem(item.id));
                                                                }
                                                            }}
                                                            styles={{ root: { display: "inline-block" } }}
                                                            autoFocus
                                                        />
                                                    </form>
                                                ) : item.isDirectory && item.newFolder && !item.isLoading ? (
                                                    <TextField
                                                        onBlur={this.saveNewFolder}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                this.saveNewFolder(e);
                                                            }

                                                            if (e.keyCode === 27) {
                                                                this.abortNewFolder();
                                                            }
                                                        }}
                                                        styles={{ root: { display: "inline-block" } }}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Link title={item.name} className="" to={url}>
                                                        {item.name}
                                                    </Link>
                                                )
                                            }
                                        </span>
                                    );
                                },
                            },
                            ...(!this.props.isSmallViewMode
                                ? [
                                    {
                                        name: localize("Typ"),
                                        fieldName: "typeName",
                                    },
                                ]
                                : []),
                            {
                                name: localize("SenastAndrad"),
                                fieldName: "date",
                                onRender: (item: Spintr.IFileBrowserFile) => {
                                    if (!item.date) {
                                        return;
                                    }

                                    return (
                                        <span>
                                            {
                                                item.date
                                            }
                                        </span>
                                    );
                                },
                            },
                            ...(!this.props.isSmallViewMode && !isGoogle
                                ? [
                                    {
                                        name: localize("UppladdadAv"),
                                        fieldName: "ownerName",
                                        onRender: (item: Spintr.IFileBrowserFile) => {
                                            if (!item.owner) {
                                                return;
                                            }

                                            return (
                                                <span>
                                                    {item.owner.name}
                                                </span>
                                            )
                                        },
                                    },
                                ]
                                : []),
                            {
                                name: "",
                                minWidth: 25,
                                maxWidth: 25,
                                onRender: (item: Spintr.IFileBrowserFile) => {
                                    var categories = [{ items: [] }] as Spintr.IActionMenuCategory[];
                                    let locked = item.isLocked && item.lockedBy !== this.props.currentUserId;

                                    if (item.isDirectory) {
                                        if (canWrite) {
                                            categories = [
                                                {
                                                    items: [
                                                        ...(!isOffice365 && !isGoogle
                                                            ? [
                                                                {
                                                                    text: localize("Flytta"),
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            isFilePickerOpen: true,
                                                                            selectedItem: item,
                                                                        });
                                                                    },
                                                                },
                                                                {
                                                                    text: localize("KopieraInnehall"),
                                                                    onClick: () => {
                                                                        this.props.dispatch(setCopiedFolder(item));
                                                                    },
                                                                },
                                                                ...(this.props.copiedFolder &&
                                                                    this.props.copiedFolder.id !== item.id
                                                                    ? [
                                                                        {
                                                                            text: localize("KlistraIn"),
                                                                            onClick: () => {
                                                                                let title = localize(
                                                                                    "ArDuSakerAttDuVillKlistraInMapp"
                                                                                )
                                                                                    .replace(
                                                                                        "{0}",
                                                                                        this.props.copiedFolder.name
                                                                                    )
                                                                                    .replace("{1}", item.name);
                                                                                this.props.dispatch(
                                                                                    setConfirmPopup({
                                                                                        isOpen: true,
                                                                                        title: title,
                                                                                        onConfirm: () => {
                                                                                            this.setState({
                                                                                                isLoading: true,
                                                                                            });
                                                                                            this.props.dispatch(
                                                                                                setCopiedFolder(
                                                                                                    null
                                                                                                )
                                                                                            );

                                                                                            api
                                                                                                .put(
                                                                                                    `/api/folders/paste/${this.props.copiedFolder.id}`,
                                                                                                    item.id,
                                                                                                    {
                                                                                                        headers: {
                                                                                                            "Content-Type":
                                                                                                                "application/json",
                                                                                                        },
                                                                                                    }
                                                                                                )
                                                                                                .then(
                                                                                                    (
                                                                                                        response: AxiosResponse
                                                                                                    ) => {
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                isLoading: false,
                                                                                                            }
                                                                                                        );
                                                                                                    }
                                                                                                )
                                                                                                .catch(() => {
                                                                                                    this.setState({
                                                                                                        isLoading: false,
                                                                                                    });
                                                                                                });
                                                                                        },
                                                                                    })
                                                                                );
                                                                            },
                                                                        },
                                                                    ]
                                                                    : []),
                                                                ...(this.props.enableFolderDownload
                                                                    ? [
                                                                        {
                                                                            text: localize("LaddaNed"),
                                                                            onClick: () => {
                                                                                this.props.dispatch(
                                                                                    setConfirmPopup({
                                                                                        isOpen: true,
                                                                                        title: localize(
                                                                                            "LaddaNerMappInfo"
                                                                                        ),
                                                                                        onConfirm: () => {
                                                                                            api.get(
                                                                                                `/api/folders/download/${item.id}`
                                                                                            );
                                                                                        },
                                                                                    })
                                                                                );
                                                                            },
                                                                        },
                                                                    ]
                                                                    : []),
                                                            ]
                                                            : []),
                                                        {
                                                            text: localize("TaBort"),
                                                            onClick: () => {
                                                                this.delete(item);
                                                            },
                                                        },
                                                        {
                                                            text: localize("BytNamn"),
                                                            onClick: () => {
                                                                this.props.dispatch(setRenamingItem(item.id));
                                                            },
                                                        },
                                                        ...(this.props.isAdmin && !isGroup && !isOffice365 && !isGoogle
                                                            ? [
                                                                {
                                                                    text: localize("Rattigheter"),
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            rightsPopup: {
                                                                                isOpen: true,
                                                                                name: item.name,
                                                                                id: item.id,
                                                                                enableOnlineEdit:
                                                                                    item.enableOnlineEdit,
                                                                            },
                                                                        });
                                                                    },
                                                                },
                                                            ]
                                                            : []),
                                                        ...(this.props.enableZoho || isOffice365 || isGoogle
                                                            ? [
                                                                {
                                                                    text: localize("SkapaNyFil"),
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            createFilePopup: {
                                                                                isOpen: true,
                                                                                id: item.id,
                                                                            },
                                                                        });
                                                                    },
                                                                },
                                                            ]
                                                            : []),
                                                    ],
                                                },
                                            ] as Spintr.IActionMenuCategory[];
                                        } else if (this.props.enableFolderDownload && !isOffice365 && !isGoogle) {
                                            categories = [
                                                {
                                                    items: [
                                                        {
                                                            text: localize("LaddaNed"),
                                                            onClick: () => {
                                                                this.props.dispatch(
                                                                    setConfirmPopup({
                                                                        isOpen: true,
                                                                        title: localize("LaddaNerMappInfo"),
                                                                        onConfirm: () => {
                                                                            api.get(
                                                                                `/api/folders/download/${item.id}`
                                                                            );
                                                                        },
                                                                    })
                                                                );
                                                            },
                                                        },
                                                    ],
                                                },
                                            ];
                                        }
                                    } else {
                                        var images = [".jpg", ".jpeg", ".bmp", ".gif", ".pdf", ".png"];
                                        let lowerCaseName = item.name.toLowerCase();
                                        var isImage = images.some((extension) => lowerCaseName.endsWith(extension));

                                        categories = [
                                            {
                                                items: [
                                                    ...(!locked && canWrite && !isOffice365 && !isGoogle
                                                        ? [
                                                            {
                                                                text: localize("Flytta"),
                                                                onClick: () => {
                                                                    this.setState({
                                                                        isFilePickerOpen: true,
                                                                        selectedItem: item,
                                                                    });
                                                                },
                                                            },
                                                        ]
                                                        : []),
                                                    ...(!locked && !this.props.isInTeamsApp
                                                        ? [
                                                            {
                                                                text: localize("LaddaNed"),
                                                                onClick: () => {
                                                                    if (item.downloadUrl) {
                                                                        window.open(item.downloadUrl, "_blank");
                                                                        return;
                                                                    } else if (isOffice365) {
                                                                        api
                                                                            .get(`/api/files/office365/${item.id}`, {
                                                                                params: {
                                                                                    source: this.props.source,
                                                                                    externalId:
                                                                                        this.props.group &&
                                                                                        this.props.group.externalId,
                                                                                },
                                                                            })
                                                                            .then((response: AxiosResponse) => {
                                                                                window.open(response.data.downloadUrl, "_blank");
                                                                            });
                                                                        return;
                                                                    }

                                                                    if (this.props.disableFileLocking || !canWrite) {
                                                                        window.location.href = `/api/servefile/${item.id}`;
                                                                        return;
                                                                    }

                                                                    this.setState({
                                                                        fileDownloadPopup: {
                                                                            isOpen: true,
                                                                            file: item,
                                                                        },
                                                                    });
                                                                },
                                                            },
                                                        ]
                                                        : []),
                                                    ...(isImage
                                                        ? [
                                                            {
                                                                text: localize("Visa"),
                                                                onClick: () => {
                                                                    window.location.href =
                                                                        item.webUrl ||
                                                                        `/api/servefile/${item.id}?show=true`;
                                                                },
                                                            },
                                                        ]
                                                        : []),
                                                    ...(!isOffice365 && !isGoogle
                                                        ? [
                                                            // TODO: Notifications? Doesn't seem to be working anyways
                                                        ]
                                                        : []),
                                                    ...(!isOffice365 && !isGoogle && !this.props.hideShare && !isGroup && !locked
                                                        ? [
                                                            {
                                                                text: localize("Dela"),
                                                                onClick: () => {
                                                                    this.setState({
                                                                        shareModal: {
                                                                            isOpen: true,
                                                                            id: item.id,
                                                                        },
                                                                    });
                                                                },
                                                            },
                                                        ]
                                                        : []),
                                                ],
                                            },
                                            ...(!locked && canWrite
                                                ? [
                                                    {
                                                        items: [
                                                            ...(!isOffice365 && !isGoogle
                                                                ? [
                                                                    {
                                                                        text: localize("KopieraLank"),
                                                                        onClick: () => {
                                                                            var fileUrl = `${window.location.protocol}//${window.location.host}/api/servefile/${item.id}`;

                                                                            // Creates a temporarly input field which hold the direct link to current file
                                                                            var tmpInput = document.createElement(
                                                                                "input"
                                                                            );
                                                                            document.body.appendChild(tmpInput);

                                                                            tmpInput.setAttribute(
                                                                                "id",
                                                                                "servefile_id"
                                                                            );

                                                                            // @ts-ignore
                                                                            (document.getElementById(
                                                                                "servefile_id"
                                                                            ) as any).value = fileUrl;

                                                                            tmpInput.select();
                                                                            document.execCommand("copy");

                                                                            // Remove element, since it's no longer needed
                                                                            document.body.removeChild(tmpInput);
                                                                        },
                                                                    },
                                                                    {
                                                                        text: localize("RedigeraMetadata"),
                                                                        onClick: () => {
                                                                            this.props.history.push({
                                                                                pathname: this.props.group
                                                                                    ? `/groups/${this.props.group.id}/files/f${item.id}/edit`
                                                                                    : `/files/${this.props.source}/f${item.id}/edit`,
                                                                            });
                                                                        },
                                                                    },
                                                                ]
                                                                : []),
                                                            ...(this.props.enableZoho &&
                                                                validZohoFormat(item.name) &&
                                                                this.props.folder &&
                                                                this.props.folder.get("enableOnlineEdit") &&
                                                                !this.props.isInTeamsApp
                                                                ? [
                                                                    {
                                                                        text: localize("RedigeraDokumentet"),
                                                                        onClick: () => {
                                                                            if (isOffice365 || isGoogle) {
                                                                                window.open(item.webUrl, "blank");
                                                                                return;
                                                                            }

                                                                            api
                                                                                .get(
                                                                                    `/api/files/${item.id}/history`
                                                                                )
                                                                                .then((response: AxiosResponse) => {
                                                                                    let version =
                                                                                        response.data.length === 0
                                                                                            ? 1
                                                                                            : response.data.length +
                                                                                            1;
                                                                                    this.props.dispatch(
                                                                                        setZohoPopup(
                                                                                            true,
                                                                                            item.size,
                                                                                            item.id,
                                                                                            version
                                                                                        )
                                                                                    );
                                                                                })
                                                                                .catch(() => {
                                                                                    this.props.dispatch(
                                                                                        setZohoPopup(
                                                                                            true,
                                                                                            item.size,
                                                                                            item.id,
                                                                                            0
                                                                                        )
                                                                                    );
                                                                                });
                                                                        },
                                                                    },
                                                                ]
                                                                : []),
                                                            {
                                                                text: localize("TaBort"),
                                                                onClick: () => {
                                                                    this.delete(item);
                                                                },
                                                            },
                                                            {
                                                                text: localize("BytNamn"),
                                                                onClick: () => {
                                                                    this.props.dispatch(setRenamingItem(item.id));
                                                                },
                                                            },
                                                        ],
                                                        title: localize("Redaktor"),
                                                    },
                                                ]
                                                : []),
                                        ];
                                    }
                                    return <ActionMenu categories={categories} />;
                                },
                            },
                        ]}
                        dragDropEvents={{
                            canDrag: (item: Spintr.IFileBrowserFile) => {
                                // TODO: Add support for dragging 365 files? broken in angular
                                return !isOffice365;
                            },
                            canDrop: (dropContext, dragContext) => {
                                return !isOffice365 && (dropContext.data as Spintr.IFileBrowserFile).isDirectory;
                            },
                            onDragStart: (item: Spintr.IFileBrowserFile) => {
                                this.draggedItem = item;
                            },
                            onDragEnd: (item: Spintr.IFileBrowserFile) => {
                                this.draggedItem = undefined;
                            },
                            onDrop: (targetItem?: Spintr.IFileBrowserFile) => {
                                if (targetItem.id === this.draggedItem.id) {
                                    return;
                                }
                                var draggedItem = this.draggedItem;
                                this.props.dispatch(
                                    setConfirmPopup({
                                        isOpen: true,
                                        title: localize("ArDuSakerPaAttDuVillFlyttaFilen"),
                                        onConfirm: () => {
                                            if (draggedItem.isDirectory) {
                                                let params = {
                                                    id: draggedItem.id,
                                                    name: draggedItem.name,
                                                    parentId: targetItem.id,
                                                    parentIdStr: targetItem.id,
                                                    source: this.props.source,
                                                };
                                                this.props.dispatch(
                                                    updateFolder(params, this.props.id)
                                                );
                                            } else {
                                                let params = {
                                                    id: draggedItem.id,
                                                    name: draggedItem.name,
                                                    folderId: targetItem.id,
                                                    source: this.props.source,
                                                };

                                                this.props.dispatch(updateFile(params, this.props.id));
                                            }
                                        },
                                    })
                                );
                            },
                        }}
                        emptyContent={
                            this.props.errors.exists ? (
                                <Stack className="error" verticalAlign="center" horizontalAlign="center">
                                    <Label>{localize("TeknisktFel")}</Label>
                                </Stack>
                            ) : (
                                canWrite ?
                                    <DropZone
                                        text={`${localize("DennaMappArTom")}. ${localize("files_drag_file")} ${localize(
                                            "KlickaHar"
                                        ).toLowerCase()} ${localize("ForAttLaddaUpp").toLowerCase()}`}
                                        isMultiple
                                        fileTypesString="*"
                                        onFilesSelected={this.onFilesSelected}
                                    /> : undefined
                            )
                        }
                        selectionMode={SelectionMode.multiple}
                        selection={this.selection}
                        orderByColumn="name"
                        disableCommandBar
                        disableSort={isOffice365}
                        infiniteScroll={true}
                    />

                    {this.state.rightsPopup.isOpen && (
                        <RightsPopup
                            isOpen
                            close={this.closeRightsPopup}
                            source={this.props.source}
                            folderName={this.state.rightsPopup.name}
                            folderId={this.state.rightsPopup.id}
                            enableOnlineEdit={this.state.rightsPopup.enableOnlineEdit}
                        />
                    )}
                    {this.state.createFilePopup.isOpen && (
                        <CreateFilePopup
                            folderId={this.state.createFilePopup.id}
                            source={this.props.source}
                            isOpen={this.state.createFilePopup.isOpen}
                            group={this.props.group}
                            close={() => {
                                this.setState({ createFilePopup: { isOpen: false } });
                            }}
                        />
                    )}
                    {this.state.fileDownloadPopup.isOpen && (
                        <FileDownloadPopup
                            isOpen={this.state.fileDownloadPopup.isOpen}
                            close={() => {
                                this.setState({
                                    fileDownloadPopup: { isOpen: false },
                                });
                            }}
                            file={this.state.fileDownloadPopup.file}
                        />
                    )}
                    {this.state.isFilePickerOpen && (
                        <FilePicker
                            onClose={() => {
                                this.setState({
                                    isFilePickerOpen: false,
                                    selectedItem: undefined,
                                });
                            }}
                            onSelect={(data, source) => {
                                let selectedItem = this.state.selectedItem;
                                if (selectedItem.isDirectory) {
                                    let params = {
                                        id: selectedItem.id,
                                        name: selectedItem.name,
                                        parentId: data[0].id,
                                        parentIdStr: data[0].id,
                                        source: source,
                                    };
                                    this.props.dispatch(updateFolder(params, this.props.id));
                                } else {
                                    let params = {
                                        id: selectedItem.id,
                                        name: selectedItem.name,
                                        folderId: data[0].id,
                                        source: source,
                                    };

                                    this.props.dispatch(updateFile(params, this.props.id));
                                }
                            }}
                            initialFolderId={this.props.group && this.props.group.folderId}
                            sourcesToDisplay={[this.props.source]}
                            source={this.props.source}
                            moveMode
                            selectFolders
                        />
                    )}
                    {this.state.shareModal.isOpen && (
                        <ShareModal objectId={this.state.shareModal.id} onDismiss={this.onShareDismiss} />
                    )}
                    {!!this.state.isInfoDialogOpen && (
                        <InfoDialog
                            message={this.state.infoDialogMessage}
                            show={this.state.isInfoDialogOpen}
                            onDismiss={this.dismissInfoDialog}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,

    folder: state.files.folders.get("items").find((i) => i.get("source") === props.source && i.get("id") === props.id),
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    isFileUser: state.profile.active.isFileUser,
    searchText: state.files.files.searchText,
    items: state.files.files.items,
    totalCount: state.files.files.totalCount,
    errors: state.files.files.queryItems.errors,
    isLoadingItems: state.files.files.queryItems.isLoading,
    isLoadingNewFile: state.files.files.isLoadingNewFile,
    selectedItems: state.files.files.selectedItems,
    currentUserId: state.profile.active.id,
    hideShare: state.files.folders.get("hideShare"),
    enableFolderDownload: state.instance.get("enableFolderDownload"),
    enableZoho: state.instance.get("enableZoho"),
    copiedFolder: state.files.folders.get("copiedFolder"),
    disableFileLocking: state.instance.get("disableFileLocking"),
    isSmallViewMode: state.ui.isSmallViewMode,
    restrictedExtensions: state.instance.get("restrictFileUploads"),
    isInTeamsApp: state.ui.isInTeamsApp,
});

export default connect(mapStateToProps)(FileBrowser);
