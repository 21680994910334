import { Label } from "@fluentui/react";
import React, { Component, RefObject } from "react";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Action } from "redux";
import { localize, localizeFormat } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { FileSelector } from "src/spintr/components";
import FilePicker from "src/spintr/components/FilePicker";
import { SpintrTypes } from "src/typings";
import { setIsTinyInModal } from "src/ui/actions";
import TinyAtFunction from "src/ui/components/Tiny/plugins/TinyAtFunction";
import TinyFormFunction from "src/ui/components/Tiny/plugins/TinyFormFunction";
import TinyQaFunction from "src/ui/components/Tiny/plugins/TinyQaFunction";
import {
    getTinyArtificialIntelligencePlugin,
    TinyArtificialIntelligencePluginId,
} from "./plugins/TinyArtificialIntelligenceFunction";
import { debounce, getTargetForLink } from "src/utils";
import tinymce, { Editor, RawEditorSettings } from "tinymce";
// tslint:disable: ordered-imports
import "tinymce/icons/default";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/code";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/paste";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/table";
import "tinymce/themes/silver";
import api from "src/spintr/SpintrApi";
import { ITinyEditorComponent } from "./Tiny.types";
import { setCalendarPopup } from "src/calendar/actions";
import { setConfirmPopup } from "src/popups/actions";

// tslint:enable: ordered-imports

interface IStateProps {
    currentUser?: IActiveUserProfile;
    instance?: any;
    enableCanea: boolean;
    enableCompletions: boolean;
}

interface IOwnProps {
    id: string,
    content: string,
    displaySaveButton?: boolean,
    onSave?: any,
    label?: string,
    required?: boolean,
    saveOnClickOutside?: boolean
    isInModal?: boolean;
    dispatch?: (action: Action) => void;
    onChange?: (value: string) => void;
    validateOnLoad?: boolean;
    ref?: any;
    apps?: any;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    language?: Spintr.Language;
    autofocus?: boolean
}

type Props = DispatchProp & IStateProps & IOwnProps;

interface IState {
    editor?: Editor,
    spnPlugins: any,
    isDirty?: boolean,
    displayRequiredFieldError: boolean
}

class TinyEditorComponent extends Component<Props, IState> implements ITinyEditorComponent {
    protected wrapperRef: RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        require("src/ui/components/Tiny/plugins/image-map");

        this.state = {
            editor: null,
            spnPlugins: this.getSpnPlugins(),
            displayRequiredFieldError: false
        };

        this.props.dispatch(setIsTinyInModal(this.props.isInModal));
        this.wrapperRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    public componentDidMount() {
        this.configureTinyMCE();

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        this.props.dispatch(setIsTinyInModal(false));
        // @ts-ignore
        tinymce.remove(this.state.editor);

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public getContent(): string {
        return this.state.editor.getContent();
    }

    public getCurrentLanguage(): Spintr.Language {
        let language = this.props.language;

        if (!language) {
            const languageId = parseInt(this.props.currentUser?.settings?.language, 10);
            if (!isNaN(languageId) && 0 < languageId) {
                language = languageId;
            }
        }

        return language || SpintrTypes.Language.English;
    }

    public render() {
        return (
            <div ref={this.wrapperRef} className={(this.state.isDirty ? "tinymce tinymce-dirty" : "tinymce") + (this.state.displayRequiredFieldError ? " required-field-border-tinymce" : "")}>
                {
                    this.props.label && (
                        <Label required={this.props.required} onClick={() => {
                            if (this.state.editor &&
                                this.state.editor.focus) {
                                this.state.editor.focus();
                            }
                        }}>{this.props.label}</Label>
                    )
                }
                <textarea
                    id={this.props.id}
                    value={this.props.content}
                    onChange={e => console.log(e)}
                />
                {
                    this.state.spnPlugins.map(spnPlugin => {
                        if (!spnPlugin.enabled ||
                            !spnPlugin.active ||
                            !spnPlugin.renderFn) {
                            return null;
                        }

                        return (
                            <div key={spnPlugin.id}>
                                {
                                    spnPlugin.renderFn(this.state.editor)
                                }
                            </div>
                        )
                    })
                }
                {
                    this.state.displayRequiredFieldError && (
                        <Label>
                            <span className="required-field-text-replica">
                                {
                                    localize("VALIDATION_FIELDNAME_IS_REQUIRED")
                                }
                            </span>
                        </Label>
                    )
                }
            </div>
        );
    }

    private isAppEnabled(id: number): boolean {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    public setPluginActive(id: string, value : boolean): void {
        this.setState({
            spnPlugins: this.state.spnPlugins.map(spnPlugin => {
                if (spnPlugin.id === id) {
                    return {
                        ...spnPlugin,
                        active: value
                    }
                } else {
                    return spnPlugin;
                }
            })
        }, () => {
            // @ts-ignore
            //document.querySelector('.tox-tbtn--enabled').click();
        });
    }

    private getSpnPlugins() {
        let dat = this;

        const spnPlugins = [{
            id: 'spnCustomSave',
            displayFirst: true,
            enabled: this.props.displaySaveButton,
            active: false,
            configFn: (editor) => {
                editor.ui.registry.addButton('spnCustomSave', {
                    text: localize("Spara"),
                    onAction: () => {
                        if (!dat.props.onSave) {
                            return;
                        }

                        dat.props.onSave(editor.getContent());
                    }
                });
            }
        }, {
            id: 'spnAtFunction',
            enabled: true,
            active: false,
            configFn: (editor) => {
                const id = 'spnAtFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("Sok"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <TinyAtFunction
                        onClose={() => this.setPluginActive('spnAtFunction', false)}
                        editor={this.state.editor} />
                )
            }
        }, {
            id: 'spnFormFunction',
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Forms),
            active: false,
            configFn: (editor) => {
                const id = 'spnFormFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("Formular"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <TinyFormFunction
                        onClose={() => this.setPluginActive('spnFormFunction', false)}
                        editor={this.state.editor} />
                )
            }
        }, {
            id: 'spnQaFunction',
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.QA),
            active: false,
            configFn: (editor) => {
                const id = 'spnQaFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("FragorOchSvar"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <TinyQaFunction
                        onClose={() => this.setPluginActive('spnQaFunction', false)}
                        editor={this.state.editor} />
                )
            }
        }, {
            id: 'spnCalendarFunction',
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Calendars),
            active: false,
            configFn: (editor) => {
                const id = 'spnCalendarFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("SkapaKalenderpost"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                this.props.dispatch(setCalendarPopup({
                    isOpen: true,
                    eventId: 0,
                    editMode: true,
                    onSave: (event: any) => {
                        this.setPluginActive('spnCalendarFunction', false);

                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            message: localize("TINY_EDITOR_CALENDAR_EVENT_CREATED_TEXT"),
                            confirmButtonText: localize("Ja"),
                            dismissButtonText: localize("Nej"),
                            onConfirm: () => {
                                this.state.editor.insertContent('<span><a href="/goto/' + event.id + '">' + event.title + '</a></span>');
                            }
                        }));
                    }
                }));

                return null;
            }
        }, {
            id: 'spnFileFunction',
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Files),
            active: false,
            configFn: (editor) => {
                const id = 'spnFileFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("Fil"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <FilePicker
                        onClose={() => this.setPluginActive('spnFileFunction', false)}
                        onSelect={(data, sourceId) => {
                            const isExternalFile = sourceId > SpintrTypes.FolderRootSource.SpintrGroup;

                            let link;
                            if (!isExternalFile && data[0]?.id) {
                                link = `/api/servefile/${data[0].id}`
                            } else {
                                link = data[0].url || data[0].filelink;
                            }
                            const name = data[0].name;
                            const html = '<a href="' + link + '" target="_blank">' + name + '</a>';

                            this.state.editor.insertContent(html);

                            this.setPluginActive('spnFileFunction', false);
                        }}
                        showFiles
                        sourcesToDisplay={[
                            SpintrTypes.FolderRootSource.Upload,
                            SpintrTypes.FolderRootSource.Office365Public,
                            SpintrTypes.FolderRootSource.SpintrPublic,
                            ...(this.props.enableCanea ? [SpintrTypes.FolderRootSource.Canea] : []),
                        ]}
                        source={SpintrTypes.FolderRootSource.Upload}
                        getUploadPromise={(file, ticket, config) => {
                            let body = new FormData();

                            body.append("file", file);
                            body.append("ticket", ticket);
                            body.append("type", "4");

                            return api.post("/api/uploads/redactor/file", body, config);
                        }}
                    />
                )
            }
        }, {
            id: 'spnImageFunction',
            enabled: true,
            active: false,
            configFn: (editor) => {
                const id = 'spnImageFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("Bild"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <FileSelector
                        onClose={() => this.setPluginActive('spnImageFunction', false)}
                        onSelect={(data) => {
                            let url = data[0].url;

                            if (data[0] &&
                                data[0].imageUrl &&
                                !data[0].url) {
                                url = data[0].originalImage;
                            } else if (data[0] &&
                                !data[0].url &&
                                data[0].filelink) {
                                url = data[0].filelink;
                            }

                            const html = `<img src="${url}" alt=""
                                ${data[0].externalAuthorName ? `data-external-author-name="${data[0].externalAuthorName}"` : ``}
                                ${data[0].externalAuthorUrl ? `data-external-author-url="${data[0].externalAuthorUrl}"`: ``}
                                ${data[0].externalPhotoUrl ? `data-external-photo-url="${data[0].externalPhotoUrl}"` : ``}
                                ${data[0].externalMediaflowId ? `data-external-mediaflow-id="${data[0].externalMediaflowId}"` : ``}
                            />`;

                            this.state.editor.insertContent(html);

                            this.setPluginActive('spnImageFunction', false);
                        }}
                        allowMultipleFiles={false}
                        fileTypesString={"image/png, image/jpeg"}
                        fileTypes={["png", "jpeg", "jpg"]}
                        sourcesToDisplay={[0, ...(this.isAppEnabled(SpintrTypes.SpintrApp.Media) ? [7] : [])]}
                        fileUploadType={1}
                        customRequestUrl={"/api/uploads/redactor/image"}
                        enableMediaflow={this.props.enableMediaflow}
                        enableUnsplash={this.props.enableUnsplash}
                    />
                )
            }
        }, {
            id: 'spnVideoFunction',
            enabled: true,
            active: false,
            configFn: (editor) => {
                const id = 'spnVideoFunction';

                editor.ui.registry.addButton(id, {
                    text: localize("Video"),
                    onAction: () => {
                        dat.setPluginActive(id, true);
                    }
                });
            },
            renderFn: () => {
                return (
                    <FileSelector
                        onlyVideo
                        onClose={() => this.setPluginActive('spnVideoFunction', false)}
                        onSelect={(data, type) => {
                            if (!type) return

                            if (type && type === 7) {
                                let content = `<video 
                                    onplay="fetch('/api/v1/blogposts/play/${data.id}', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({url: '${data.name}'})});"
                                    style="width: 500px; height: 281px;" 
                                    class="player zxc azuremediaplayer amp-default-skin amp-big-play-centered" 
                                    controls="">
                                    <source src=${data.name} />
                                    </video>`
                                this.state.editor.insertContent(content);
                                this.setPluginActive('spnVideoFunction', false);
                                return
                            }

                            if (type === 6) {
                                let stripTags = (input) => {
                                    return input.replace(/(<([^>]+)>)/gi, '');
                                };

                                if (!data.match(/<iframe|<video/gi)) {
                                    data = stripTags(data);

                                    // parse if it is link on youtube & vimeo
                                    let iframeStart = '<iframe style="width: 500px; height: 281px;" src="';
                                    let iframeEnd = '" frameborder="0" allowfullscreen></iframe>';
                                    let reUrlYoutube = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com\S*[^\w\-\s])([\w\-]{11})(?=[^\w\-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
                                    let reUrlVimeo = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

                                    if (data.match(reUrlYoutube)) {
                                        data = data.replace(reUrlYoutube, iframeStart + '//www.youtube.com/embed/$1' + iframeEnd);
                                    } else if (data.match(reUrlVimeo)) {
                                        data = data.replace(reUrlVimeo, iframeStart + '//player.vimeo.com/video/$2' + iframeEnd);
                                    }
                                }

                                this.state.editor.insertContent(data);

                                this.setPluginActive('spnVideoFunction', false);
                            }
                        }}
                        sourcesToDisplay={[6, ...(this.isAppEnabled(SpintrTypes.SpintrApp.Media) ? [7] : [])]} />
                )
            }
        }, {
            id: 'stickytoolbar',
            enabled: true,
            active: false,
            configFn: (editor) => {
                var offset = editor.settings.sticky_offset ? editor.settings.sticky_offset : 0;

                editor.on('init', function () {
                    setSticky();
                });

                const debouncedSetSticky = debounce(() => setSticky(), 0);

                window.addEventListener('scroll', debouncedSetSticky, true);

                function setSticky() {
                    var container = editor.getContainer();

                    if (!editor.inline && container && container.offsetParent) {
                        var menubar = container.querySelector('.mce-menubar'),
                            toolbar = container.querySelector('.tox-editor-header');

                        if (isSticky()) {
                            if (isAtBottom()) {
                                if (menubar) {
                                    menubar.style.top = null;
                                    menubar.style.borderBottom = null;

                                    menubar.style.bottom = toolbar.offsetHeight + 'px';
                                    menubar.style.position = 'absolute';
                                    menubar.style.width = '100%';
                                }

                                toolbar.style.top = null;
                                toolbar.style.borderBottom = null;

                                toolbar.style.bottom = '0 px';
                                toolbar.style.position = 'absolute';
                                toolbar.style.width = '100%';
                            } else {
                                if (menubar) {
                                    menubar.style.bottom = null;

                                    menubar.style.top = offset + 'px';
                                    menubar.style.position = 'fixed';
                                    menubar.style.width = container.clientWidth + 'px';
                                    menubar.style.backgroundColor = '#f0f0f0';
                                }

                                toolbar.style.bottom = null;

                                toolbar.style.top = (menubar ? menubar.offsetHeight + offset : offset) + 'px';
                                toolbar.style.position = 'fixed';
                                toolbar.style.width = container.clientWidth + 'px';
                                toolbar.style.borderBottom = '1px solid rgba(0, 0, 0, 0.2)';
                            }
                        } else {
                            if (menubar) {
                                menubar.style.position = 'absolute';
                                menubar.style.top = null;
                                menubar.style.width = container.clientWidth + 'px';
                                menubar.style.borderBottom = null;
                            }

                            toolbar.style.position = 'absolute';
                            toolbar.style.top = null;
                            toolbar.style.width = container.clientWidth + 'px';
                            toolbar.style.borderBottom = null;
                        }

                        var editArea = container.querySelector('.tox-edit-area');

                        if (editArea && toolbar) {
                            editArea.style.marginTop = toolbar.offsetHeight + 'px';
                        }
                    }
                }

                function isSticky() {
                    var editorPosition = editor.getContainer().getBoundingClientRect().top;

                    if (editorPosition < offset) {
                        return true;
                    }

                    return false;
                }

                function isAtBottom() {
                    var container = editor.getContainer(),
                        editorPosition = container.getBoundingClientRect().top,
                        menubar = container.querySelector('.mce-menubar');

                    var statusbarHeight = 0,
                        toolbarHeight = 0;

                    var menubarHeight = menubar ? menubar.offsetHeight : 0;

                    var stickyHeight = -(container.offsetHeight - menubarHeight - statusbarHeight - toolbarHeight);

                    if (editorPosition < stickyHeight + offset) {
                        return true;
                    }

                    return false;
                }
            }
        }, getTinyArtificialIntelligencePlugin(dat, this.props.enableCompletions),
        ];

        return spnPlugins;
    }

    private configureTinyMCE() {
        for (const spnPlugin of this.state.spnPlugins) {
            tinymce.PluginManager.add(spnPlugin.id, spnPlugin.configFn);
        }

        const getToolbarConfig = () => {
            let segments = [
                "undo redo",
                "bold italic strikethrough underline removeformat",
                "formatselect",
                "forecolor backcolor",
                "fontselect",
                "insert",
                "align",
                "bullist numlist",
                "outdent indent",
                "table",
                "alignment",
                "code",
                "link",
                "hr"
            ];

            for (const spnPlugin of this.state.spnPlugins) {
                if (!spnPlugin.enabled) {
                    continue;
                }

                if (spnPlugin.id === TinyArtificialIntelligencePluginId) {
                    segments.splice(
                        segments.findIndex((segment) => segment.indexOf("fontselect") > -1),
                        0,
                        spnPlugin.id
                    );                    
                } else if (spnPlugin.displayFirst) {
                    segments.unshift(spnPlugin.id);
                } else {
                    segments.push(spnPlugin.id)
                }
            }

            return segments.join(" | ");
        }

        const getPluginsConfig = () => {
            let plugins = "link code autoresize table lists advlist stickytoolbar hr paste image imagetools imageMap quickbars";

            for (const spnPlugin of this.state.spnPlugins) {
                if (!spnPlugin.enabled) {
                    continue;
                }

                if (spnPlugin.displayFirst) {
                    plugins = spnPlugin.id + " " + plugins;
                } else {
                    plugins = plugins + " " + spnPlugin.id;
                }
            }

            return plugins;
        }

        const getContentStyle = () => {
            return '@font-face {font-family: "Eloquia";font-style: normal;font-weight: 400;font-display: swap;src: url("/style/Fonts/Eloquia/EloquiaText-Regular.otf")} body { font-family: Eloquia, sans-serif; font-size: 14px; color: #22234A; } p { margin: 0; line-height: 1.5em; }';
        }

        const getFontFormats = () => {
            const bodyFontUrl = this.props.instance.get("bodyFontUrl");

            if (bodyFontUrl) {
                return `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;${localize("Standard")} (${localize("OWN_FONT")})=SpintrCustomBodyFont, sans-serif;${localize("Rubrik")} (${localize("OWN_FONT")})=SpintrCustomHeadlineFont, sans-serif; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`;
            } else {
                return "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Eloquia=Eloquia, sans-serif;Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Quicksand=Quicksand, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";
            }
        }

        let quickbars_selection_toolbar = "bold italic | formatselect | link blockquote";
        if (this.props.enableCompletions) {
            quickbars_selection_toolbar += ` | ${TinyArtificialIntelligencePluginId}`;
        }        

        const settings: RawEditorSettings = {
            extended_valid_elements: "video[controls=controls|width|height|style|src|type|poster|preload|onplay|onended]",
            browser_spellcheck: true,
            relative_urls: false,
            auto_focus: !this.props.autofocus ? "" : this.props.id,
            remove_script_host: true,
            font_formats: getFontFormats(),
            content_style: getContentStyle(),
            min_height: 400,
            // @ts-ignore
            sticky_offset: this.props.isInModal ?
                16 :
                0,
            document_base_url: window.location.origin + "/",
            selector: `#${this.props.id}`,
            language: this.getLangTag(),
            language_url: this.getLangUrl(),
            skin_url: `${process.env.PUBLIC_URL}/tinymce-assets/skins/ui/oxide`,
            content_css: `${process.env.PUBLIC_URL}/tinymce-assets/tiny.css`,
            menubar: false,
            statusbar: false,
            toolbar: getToolbarConfig(),
            toolbar_drawer: 'sliding',
            formats: {
                removeformat: [
                    {
                        selector: 'b,strong,em,i,font,u,strike,s,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins,small,hr,h1,h2,h3,h4,h5,h6,h7,h8,a,ul,li',
                        remove: 'all',
                        split: true,
                        block_expand: true,
                        expand: false,
                        deep: true
                    }]
            },
            plugins: getPluginsConfig(),
            imagetools_toolbar: "imageoptions imageMap alignleft aligncenter alignright",
            paste_data_images: true,
            smart_paste: true,
            target_list: [
                { text: localize("OppnaINuvarandeFlik"), value: '' },
                { text: localize("OppnaINyFlik"), value: '_blank' }
            ],

            paste_preprocess: (plugin, args) => {
                if (!args ||
                    !args.content) {
                    return;
                }

                const isValidURL = (s) => {
                    const result = s.indexOf(" ") === -1 &&
                        (s.indexOf("http://") === 0 || s.indexOf("https://") === 0);

                    return result;
                };

                if (!isValidURL(args.content)) {
                    return;
                }

                const link = '<p><a href="' + args.content + '" target="' + getTargetForLink(args.content) + '">' + args.content + '</a></p> ';

                args.content = link;
            },
            automatic_uploads: true,
            images_upload_url: "/api/uploads/redactor/image",
            imagetools_cors_hosts: ['localhost',
                '127.0.0.1',
                'spintr.io',
                'spintr.me',
                'spintrsite.blob.core.windows.net',
                'sledgehammerstorage01.blob.core.windows.net',
                'sledgehammerstorage02.blob.core.windows.net',
                'sledgehammerstorage03.blob.core.windows.net',
                'sledgehammerstorage04.blob.core.windows.net',],
            a11y_advanced_options: true,
            contextmenu: false,
            setup: editor => {
                this.setState({ editor });

                if (this.props.validateOnLoad) {
                    setTimeout(() => {
                        let content = editor.getContent();

                        if (content.length === 0) {
                            this.setState({
                                displayRequiredFieldError: true
                            }, () => {
                                let elements = document.getElementsByClassName("tox-tinymce");

                                if (elements &&
                                    elements.length > 0 &&
                                    !elements[0].hasAttribute("aria-invalid")) {
                                    elements[0].setAttribute("aria-invalid", "true");
                                }
                            });
                        }
                    }, 500);
                }

                editor.on('focus blur', (e) => {
                    if (this.props.required) {
                        let content = this.state.editor.getContent();

                        if (content.length === 0) {
                            this.setState({
                                displayRequiredFieldError: true
                            }, () => {
                                let elements = document.getElementsByClassName("tox-tinymce");

                                if (elements &&
                                    elements.length > 0 &&
                                    !elements[0].hasAttribute("aria-invalid")) {
                                    elements[0].setAttribute("aria-invalid", "true");
                                }
                            });
                        }
                    }
                });

                editor.on("change", (ev) => {
                    if (!this.state.isDirty) {
                        this.setState({
                            isDirty: true
                        });
                    }

                    let content;

                    if (this.state.displayRequiredFieldError) {
                        content = this.state.editor.getContent();

                        if (content.length > 0) {
                            this.setState({
                                displayRequiredFieldError: false
                            }, () => {
                                let elements = document.getElementsByClassName("tox-tinymce");

                                if (elements &&
                                    elements.length > 0 &&
                                    elements[0].hasAttribute("aria-invalid")) {
                                    elements[0].removeAttribute("aria-invalid");
                                }
                            });
                        }
                    }

                    if (!this.props.onChange) {
                        return;
                    }

                    if (!content) {
                        content = this.state.editor.getContent();
                    }

                    this.props.onChange(content);
                }, false);

                editor.on('ResizeContent', (e) => {
                    if (document.activeElement.tagName === "IFRAME") { // needed to fix AC-552
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                    }
                });
            },
            quickbars_selection_toolbar,
            quickbars_insert_toolbar: false,
        };

        tinymce.init(settings);
    }

    private getLangTag() {
        let langKey = this.props.language?.toString() || this.props.currentUser.settings.language;

        let langTags = {
            1: "sv_SE",
            2: "nb_NO",
            3: "en_US",
            4: "de",
            5: "fi",
            6: "da",
        };

        return langTags[langKey];
    }

    private getLangUrl() {
        let langTag = this.getLangTag();

        if (langTag === "en_US") {
            // A lang file must me loaded, or else tinymce loads slower,
            // and a english lang file doesn't exist

            return `${process.env.PUBLIC_URL}/tinymce-assets/langs/sv_SE.js`;
        }

        return `${process.env.PUBLIC_URL}/tinymce-assets/langs/${langTag}.js`;
    }

    private handleClickOutside(event) {
        const isClickOnScrollBar = event.target.className === "contentWrap" &&
            (event.target.clientWidth - event.x) < 6;

        if (this &&
            this.props &&
            this.props.saveOnClickOutside &&
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target) &&
            document.getElementsByClassName('appWrap')[0].contains(event.target) &&
            !isClickOnScrollBar) {
            this.props.onSave(this.state.editor.getContent());
        }
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        currentUser: state.profile.active,
        instance: state.instance,
        enableCanea: state.instance.get("caneaUrl") !== "",
        enableCompletions: state.instance.get("aiCompletionsEnabled"),
        apps: state.app
    });

export default connect(mapStateToProps, null, null, { forwardRef: true })(TinyEditorComponent);
